
import ListaTipoOperacaoCompressor from "./ListaTipoOperacaoCompressor";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";
export default function TipoOperacaoCompressorForm({ styles }) {

    return(
        <AlertDialogOnClose nome={"Tipo Operação Compressor"} placeholder={"Insira o nome da operação"}>
            <ListaTipoOperacaoCompressor />
        </AlertDialogOnClose>
    )
} 