import React from 'react';
import IconStatus from './IconStatus';
import Table from '../../common/table';
import { format } from 'date-fns';

const TableAlarmCompressor = ({ listaAlarmeCompressores }) => {
	
	function getDuracao(dataInicio) {
		const ms = new Date() - new Date(dataInicio);
		let hours = parseInt(ms / (1000 * 60 * 60));
		let minutes = parseInt((ms % (1000 * 60 * 60)) / 60000);

		return formatNumber(hours) + 'h' + formatNumber(minutes);
	}

	function formatNumber(num) {
		return num.toString().length <= 1 ? '0' + num : num;
	}

	return (
		<div className="w-full max-h-36">
			<Table.Root>
				<Table.Head>
					<Table.Header>Data/Hora</Table.Header>
					<Table.Header>
						<p className="lg:hidden block">CP</p>
						<p className="hidden lg:block">
							Compressor
						</p>
					</Table.Header>
					<Table.Header>Alarme</Table.Header>
					<Table.Header>Valor</Table.Header>
					<Table.Header>Duração</Table.Header>
					<Table.Header>Status</Table.Header>
				</Table.Head>

				<Table.Body>
					{listaAlarmeCompressores.length > 0 ? (
						listaAlarmeCompressores.map(
							(compressorAlarme, i) => (
								<Table.Row
									key={i}
									className="py-2"
								>
									<Table.Data>
										{format(
											compressorAlarme.dataHoraInicio, 'dd.MM, HH:mm'
										)}
									</Table.Data>
									<Table.Data>
										{
											compressorAlarme.equipamentoNome
										}
									</Table.Data>
									<Table.Data>
										{
											compressorAlarme.descricao
										}
									</Table.Data>
									<Table.Data>
										{compressorAlarme.valor.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
										)}
									</Table.Data>
									<Table.Data>
										{getDuracao(
											compressorAlarme.dataHoraInicio
										)}
									</Table.Data>
									<Table.Data>
										<IconStatus status="warning" />
									</Table.Data>
								</Table.Row>
							)
						)
					) : (
						<Table.Row>
							<Table.Data
								span={6}
								className="text-secondary"
							>
								<br />
								Nenhum alarme ativo
							</Table.Data>
						</Table.Row>
					)}
				</Table.Body>
			</Table.Root>
		</div>
	);
};

export default TableAlarmCompressor;
