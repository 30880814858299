import { useEffect, useState } from "react";
import { DatePicker } from "../ui/datepicker";
import { Button } from "../ui/button";
import DateIntervalPopOver from "../relatorio/DateIntervalPopOver";
import { FaFileExcel, FaSpinner } from "react-icons/fa";

export default function DatePickerGetData({onSearch, gerarRelatorio, loading}) {

    const [tempSelectedDate, setTempSelectedDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setTempSelectedDate(date);
    };

    const handleSearch = async () => {
        setSelectedDate(tempSelectedDate);
    };

    useEffect(() => {
        onSearch(selectedDate);
    },[selectedDate, onSearch]);


    return (
        <div className="flex items-center justify-center">
            <div className="flex justify-center items-center w-full p-4 gap-2">
                <DatePicker
                    selectedDate={tempSelectedDate}
                    onDateChange={handleDateChange}
                />
                <Button onClick={handleSearch}>
                    Buscar
                </Button>
                <DateIntervalPopOver onGerarRelatorio={gerarRelatorio} disabled={loading}>
                    {
                        loading ?
                            <div className="animate-spin">
                                <FaSpinner size={18} />
                            </div>
                            :
                            <FaFileExcel size={18} />
                    }

                </DateIntervalPopOver>
            </div>
            <div className="inline-flex w-32 justify-end px-2">
                <p className="text-sm text-secondary drop-shadow-1xl">
                    {selectedDate
                        ? selectedDate.toLocaleDateString(
                            'pt-BR',
                            {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }
                        )
                        : ''}
                </p>
            </div>
        </div>
    )
}