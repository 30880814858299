import { useState } from "react";
import ListaUnidade from "./ListaUnidade";
import { AlertDialog, AlertDialogTrigger } from "../../../../components/ui/alert-dialog";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";
export default function UnidadeForm() {
    const [key, setKey] = useState(1);
    const [pesquisar, setPesquisar] = useState('');
    const [funcaoModal, setFuncaoModal] = useState('Adicionar');
    const [isOpen, setIsOpen] = useState(false);

    const funcaoModalController =  {
        funcaoModal:funcaoModal,
        setFuncaoModal:setFuncaoModal,
    }


    function atualizarLista() {
        setKey(key + 1);
    }

    function pesquisarNome(e) {
        e.preventDefault();
        setPesquisar(e.target.inputPesquisar.value);
    }    

    const onClose = () => {
        setIsOpen(false);
    }

    return(
        <>
        <AlertDialogOnClose nome={"Unidade"} placeholder={"Insira o nome da unidade"}>
            <ListaUnidade />
        </AlertDialogOnClose>
        </>
    )
} 