import CardLayout from '../../../common/CardLayout';

export default function StatusCondensadores({
	condensadores,
	alarmes
}) {

	return (
		<CardLayout>
			<div>
				<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl text-center mb-1.5">
					Status dos Condensadores
				</h2>
				<div className='flex flex-wrap w-full gap-1'>
					{condensadores.map((condensador) => (
						<div
							key={condensador.id}
							className={"flex w-full flex-1 gap-x-1 rounded-md border-[1px] border-gray-700 " + (alarmes.find((alarme) => alarme.tipoAlarme === 'COND_EQUIPAMENTOS' && alarme.equipamentoId === condensador.id) && "animate-warning")} 
						>
							<div className="flex flex-col justify-center items-center bg-accent/20 rounded-bl-md rounded-tl-md py-[1px] px-[5px]">
								<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl min-w-20 text-center w-full whitespace-nowrap">
									{condensador.nome}
								</h2>
							</div>
							{/* divider */}
							<div className="w-px h-full bg-gray-700 -ml-1"></div>
							<div className="flex  justify-center items-center gap-[1px] w-full mr-1 pr-[2px] px-2">
								{alarmes.filter(({ equipamentoIdRelacionado }) => equipamentoIdRelacionado === condensador.id).length > 0 ? (
									<span className="text-alarm flex justify-center items-center text-[20px]">
										<span className="animate-ping inline-block h-4 w-4 rounded-full ring-2 ring-alarm/40 bg-alarm mx-4"></span>
										({alarmes.filter(({ equipamentoIdRelacionado }) => equipamentoIdRelacionado === condensador.id).length})
									</span>
								) : (
									<div className="flex w-full h-full justify-center items-center">
										-
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</CardLayout>
	);
}

