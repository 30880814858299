import ListaGrupos from './ListaGrupos';
import AlertDialogOnClose from '../../../../components/admin/AlertDiologOnClose';

export default function GrupoForm() {

	return (
		<AlertDialogOnClose nome={"Grupo"} placeholder={"Insira o nome do grupo"}>
			<ListaGrupos />
		</AlertDialogOnClose>
	);
}
