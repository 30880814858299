import { useContext, useEffect, useState } from 'react';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import apiGrupo from '../../../../services/apis/apiGrupo';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';

export default function ListaEmpresas({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose
}) {
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [idEditar, setIdEditar] = useState();
	const [nome, setNome] = useState('');
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const {page} = useParams();
	const [pagination, setPagination] = useState();
	const [listaGrupo, setListaGrupo] = useState([]);
	const { erro, sucesso } = useContext(ToastContext);
	const [grupoId, setGrupoId] = useState(0);
	const funcaoModal = funcaoModalController.funcaoModal;

	useEffect(() => {
        apiGrupo.fetchApi().then(res => {
            setListaGrupo(res.data);
        })
    },[]) 

	//GET EMPRESAS
	useEffect(() => {
		apiEmpresa.fetchApi(page).then((res) => {
			setPagination(res.data)
			setListaEmpresas(res.data.items);
		});
	}, [page]);



	//DELETE EMPRESA
	function deletar(id) {
		apiEmpresa
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Empresa deletada com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar empresa!');
			});
	}

	function enviar(e) {
		e.preventDefault();
		const empresa = {
			nome: e.target.nome.value,
			grupoId: parseInt(e.target.grupoId.value),
			logo: '123',
		};

		if (funcaoModal === 'Adicionar') {
			apiEmpresa
				.postApi(empresa)
				.then((res) => {
					atualizarLista();
					setNome('');
					sucesso('Empresa adicionada com sucesso!');
					onClose();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao adicionar empresa!');
				});
			} else if (funcaoModal === 'Editar') {
				apiEmpresa
				.putApi(idEditar, empresa)
				.then((res) => {
					atualizarLista();
					sucesso('Empresa editada com sucesso!');
					onClose();
				})
				.catch((error) => {
					erro('Erro ao editar empresa!');
					console.error(error.message);
				});
		}
	}

	function pegarNomeGrupo(id) {
		try {
			const index = listaGrupo.findIndex((elemento) => {
				return elemento.id === id;
			});
			return listaGrupo[index].nome;
		} catch (error) {}
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Grupo
								</th>
							</tr>
						</thead>
						<tbody>
							{listaEmpresas.map(
								(empresa, i) =>
									empresa.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													empresa.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{pegarNomeGrupo(
														empresa.grupoId
													)}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																setIdEditar(
																	empresa.id
																);
																setNome(
																	empresa.nome
																);
																setGrupoId(
																	empresa.grupoId
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	empresa.nome
																);
																setIdRemover(
																	empresa.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination}/>
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Empresa
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full flex justify-between my-5 px-10">
						<div className="flex flex-col justify-end">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome
							</label>
							<input
								id="nome"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								type="text"
								placeholder="nome da empresa"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>

						<div className="flex flex-col justify-end">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Grupo{' '}
							</label>
							<select
								id="grupoId"
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								value={grupoId}
								required
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label=" "> </option>
								{listaGrupo.map(
									(elemento, i) => (
										<option
											value={
												elemento.id
											}
											key={i}
										>
											{
												elemento.nome
											}
										</option>
									)
								)}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
