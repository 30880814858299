'use client';
import Chart from 'react-apexcharts';

const ChartMixedDefault = ({ chartDataProps, typeChart, nameSerie }) => {
	let data = [];
	let referencia;
	let max1 = 100;
	let max2 = 100
	if (typeChart === 'ganhoEnergetico') {
		data = chartDataProps.map(
			(data) => data.leiturasCondensadores[0]?.ganhoEnergetico || 0
		);
		data[0] = 20
		max1 = 40
		max2 = 40
		referencia = 20;
	} else if (typeChart === 'umidadeRelativaSaida') {
		data = chartDataProps.map(
			(data) => data.leiturasCondensadores[0]?.umidadeRelativaSaida || 0
		);

		max1 = 133;
		max2 = 210;
		referencia = 210;
	}



	const qtd = chartDataProps.map((data) => data.nome).length;
	let tamFont;

	if (qtd <= 10) {
		tamFont = '16px';
	} else if (qtd <= 20) {
		tamFont = '14px';
	} else {
		tamFont = '11px';
	}

    const options = {
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false,
            },
        },
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 3,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: '90%',
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
            enabledOnSeries: [0],
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: tamFont,
			},
            background: {
                enabled: false // Desabilita o fundo dos dataLabels
            },
			offsetY: 0,
			offsetX: 0,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
			formatter: function (val, index) {
				return val.toLocaleString('pt-BR', {
					maximumFractionDigits: 2,
				});
			},
		},
		stroke: {
			width: 2,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps.map((data) => data.nome),
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: 'white',
					fontSize: tamFont,
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
        yaxis: [
            {
                reversed: false,
                opposite: false,
                floating: false,
                axisBorder: {
                    show: false,
                },
				max: max1,
                axisTicks: {
                    show: false,
                    color: '#40475D',
                },
                labels: {
                    show: false,
                    style: {
                        colors: 'white',
                        fontSize: '12px',
                        fontStyle: 'bold',
                    },
                    formatter: function (val, index) {
                        return val.toLocaleString('pt-BR', {
                            maximumFractionDigits: 2,
                        });
                    },
                }
            },
            {
                reversed: false,
                opposite: false,
                floating: false,
                axisBorder: {
                    show: false,
                },
				max: max2,
                axisTicks: {
                    show: false,
                    color: '#40475D',
                },
                labels: {
                    show: false,
                    style: {
                        colors: 'white',
                        fontSize: '12px',
                        fontStyle: 'bold',
                    },
                    formatter: function (val, index) {
						if(typeChart === 'umidadeRelativaSaida') {
							return 95;
						}

                        return val.toLocaleString('pt-BR', {
                            maximumFractionDigits: 2,
                        });
                    },
                }
            },
        ],
        fill: {
			type: ['gradient', 'solid'],
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#6078ea'], 
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		tooltip: {
			theme: 'dark',
		},
        legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
      
    };
    
    const series = [
        {
            name: nameSerie ? nameSerie : '',
            data: data,
            color: '#0CABF5',
            type: "bar",
        },
        {
            name: 'Referência',
            data: chartDataProps.map((data) => referencia),
            color: '#00E396',
            type: "line",
        }
    ];
    

	return (
		<div className="bg-transparent w-full h-full px-[1px]">
			<Chart
				options={options}
				series={series}
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartMixedDefault;
