import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraEnergia = {

    //MENSAL
    getLeituraMensal: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/mensal?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);

            })
    },
    getLeiturasEnergiaByDate: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/data?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },
    getLeituraMensalDetalhado: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/consumo-detalhado-mensal?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);

            })
    },
    //DIARIO
    getLeituraDiario: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/diaria?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);

            })
    },

    getLeituraDiarioDetalhado: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/consumo-detalhado-diario?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);

            })
    },

    //DEMANDA ATIVA
    getLeituraDemandaAtiva: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/demanda-ativa?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    getLeituraDemandaAtivaDetalhado: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/demanda-ativa-detalhado-diario?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    //DEMANDA REATIVA
    getLeituraDemandaReativa: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/demanda-reativa?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    getLeituraDemandaReativaDetalhado: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/demanda-reativa-detalhado-diario?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },


    getLeituraSalaMaquinas: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/monitoramento-sala-maquinas?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    getUltimaLeitura: async (id) => {
        const URL = `${apiLink}api/leitura-energia/ultima-leitura?unidadeId=` + id;
        return await axiosInstance.post(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    getEficiencia: async (id) => {
        const URL = `${apiLink}api/leitura-energia/eficiencia-energetica/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

    getConsumoDiario: async (id, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/leitura-energia/consumo-diario?unidadeId=` + id;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },

}

export default apiLeituraEnergia;
