import ListaModeloCompressores from "./ListaModeloCompressores";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";

export default function ModeloCompressorForm({ styles }) {

    return (
        <AlertDialogOnClose nome={"Modelo Compressor"} placeholder={"Insira o nome do modelo"}>
            <ListaModeloCompressores />
        </AlertDialogOnClose>
    )
} 