import ListaEmpresas from "./ListaEmpresas";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";
export default function EmpresaForm() {

    return(
        <>
            <AlertDialogOnClose nome={"Empresa"} placeholder={"Insira o nome da empresa"}>
                <ListaEmpresas />
            </AlertDialogOnClose>
        </>
    )
} 