import { cloneElement, useState } from "react";
import { AlertDialog, AlertDialogTrigger } from "../ui/alert-dialog";

export default function AlertDialogOnClose({ children, nome, placeholder = nome }) {

    const [isOpen, setIsOpen] = useState(false);
    const [key, setKey] = useState(1);
    const [funcaoModal, setFuncaoModal] = useState('Adicionar');
    const [pesquisar, setPesquisar] = useState('');

    const onClose = () => {
        setIsOpen(false);
    }

    function atualizarLista() {
        setKey(key + 1);
    }

    function search(e) {
        e.preventDefault();
        setPesquisar(e.target.inputPesquisar.value);
    }

    const funcaoModalController = {
        funcaoModal: funcaoModal,
        setFuncaoModal: setFuncaoModal,
    }


    return (
        <AlertDialog onOpenChange={() => setIsOpen(true)} open={isOpen}>

            <div className="pt-[50px] max-w-full">

                <h1 className="text-[45px] md:ml-5 max-sm:text-center">{nome}</h1>

                <div className="flex content-center justify-center mt-[50px]">

                    <div className="flex flex-wrap justify-center">
                        <form onSubmit={(e) => search(e)}>
                            <input type="text" className="bg-transparent rounded-l-md border border-lightBlue p-1 text-[19px] h-[40px] align-bottom " id="inputPesquisar" name="inputPesquisar" placeholder={placeholder} />
                            <button className="rounded-r-md bg-lightBlue w-[40px] h-[40px] max-sm:" title="Pesquisar">
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </form>
                        <AlertDialogTrigger>
                            <div className="rounded-md bg-lightBlue w-[40px] h-[40px] md:ml-3 cursor-pointer max-sm:mt-4 max-sm:w-full flex justify-center items-center" title="Adicionar" onClick={() => setFuncaoModal('Adicionar')}>
                                <i className="fa-solid fa-plus"></i>
                            </div>
                        </AlertDialogTrigger>
                    </div>

                </div>
                {cloneElement(children, { onClose, atualizarLista, funcaoModal, pesquisar, key, funcaoModalController})}
            </div>


        </AlertDialog>
    )

}