import { useEffect, useState } from 'react';
import { Button } from '../../../components/ui/button';
import { DatePicker } from '../../../components/ui/datepicker';
import ConsumoEnergiaMensal from '../../../components/monitoramento/energia/ConsumoEnergiaMensal';
import ConsumoEnergiaDiario from '../../../components/monitoramento/energia/ConsumoEnergiaDiario';
import DemandaAtivaDiario from '../../../components/monitoramento/energia/DemandaAtivaDiario';
import SaladeMaquinasDiario from '../../../components/monitoramento/energia/SaladeMaquinasDiario';
import DemandaReativaDiario from '../../../components/monitoramento/energia/DemandaReativaDiario';
import apiLeituraEnergia from '../../../services/apis/Leituras/apiLeituraEnergia';
import { useNavigate } from 'react-router-dom';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';
import { getClientTimeZoneOffset } from '../../../funcs';

function MonitoramentoEnergia() {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [tempSelectedDate, setTempSelectedDate] = useState(new Date());

	const [leituraEnergia, setLeituraEnergia] = useState([]);
	
	const [consumoEnergiaMensal, setConsumoEnergiaMensal] = useState([]);
	const [consumoEnergiaMensalDetalhado, setConsumoEnergiaMensalDetalhado] =
	useState();
	
	const [consumoEnergiaDiario, setConsumoEnergiaDiario] = useState([]);
	const [consumoEnergiaDiarioDetalhado, setConsumoEnergiaDiarioDetalhado] =
	useState();
	const [
		consumoEnergiaDemandaAtivaDetalhado,
		setConsumoEnergiaDemandaAtivaDetalhado,
	] = useState();
	
	const [
		consumoEnergiaDemandaReativaDetalhado,
		setConsumoEnergiaDemandaReativaDetalhado,
	] = useState();
	
	const [energia, setEnergia] =
	useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		const unId = getCurrentUnidadeId();
		const formattedDate = selectedDate
			? `${selectedDate.getFullYear()}-${(
					selectedDate.getMonth() + 1
			  )
					.toString()
					.padStart(2, '0')}-${selectedDate
					.getDate()
					.toString()
					.padStart(2, '0')}`
			: '';
		const dataObject = {
			timeZoneNumber: getClientTimeZoneOffset(),
			data: formattedDate,
		}

		try {
			if (unId === null) {
				navigate('/escolha-unidade');
				throw new Error('Unidade não selecionada');
			}
			//GET LEITURAS
			//MENSAL
			apiLeituraEnergia
				.getLeituraMensal(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaMensal(res.data);
				});
			//MENSAL DETALHADO
			apiLeituraEnergia
				.getLeituraMensalDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaMensalDetalhado(res.data[0]);
				});
			//DIARIO
			apiLeituraEnergia
				.getLeituraDiario(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDiario(res.data);
				});
			//DIARIO DETALHADO
			apiLeituraEnergia
				.getLeituraDiarioDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDiarioDetalhado(res.data[0]);
				});
			apiLeituraEnergia
				.getLeituraDemandaAtivaDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDemandaAtivaDetalhado(
						res.data[0]
					);
				});

			apiLeituraEnergia
				.getLeituraDemandaReativaDetalhado(unId, dataObject)
				.then((res) => {
					setConsumoEnergiaDemandaReativaDetalhado(
						res.data[0]
					);
				});
			apiLeituraEnergia.getLeiturasEnergiaByDate(unId, dataObject)
			.then((res) => {
				setLeituraEnergia(res.data);
			});
			//SALA DE MÁQUINAS
			apiLeituraEnergia
				.getLeituraSalaMaquinas(unId, dataObject)
				.then((res) => {
					setEnergia(res.data);
				});

		} catch (error) {
			console.error(error.message);
		}
	}, [selectedDate, navigate]);

	const handleDateChange = (date) => {
		setTempSelectedDate(date);
	};

	const handleSearch = async () => {
		setSelectedDate(tempSelectedDate);
	};

	useEffect(() => {
		if (localStorage.getItem('unId') === null) {
			navigate('/escolha-unidade');
		}
	}, [navigate]);

	return (
		<>
			<div className="pb-8">
				<div className="flex items-center justify-center">
					<div className="flex justify-center items-center w-full p-4 gap-2">
						<DatePicker
							selectedDate={tempSelectedDate}
							onDateChange={handleDateChange}
						/>
						<Button onClick={handleSearch}>
							Buscar
						</Button>
					</div>
					<div className="inline-flex w-32 justify-end px-2">
						<p className="text-sm text-secondary drop-shadow-1xl">
							{selectedDate
								? selectedDate.toLocaleDateString(
										'pt-BR',
										{
											day: '2-digit',
											month: '2-digit',
											year: 'numeric',
										}
								  )
								: ''}
						</p>
					</div>
				</div>
				<ConsumoEnergiaMensal
					dados={consumoEnergiaMensal}
					dadosDetalhado={consumoEnergiaMensalDetalhado}
				/>
				<div className="flex justify-center gap-4 flex-col p-2">
					<div className="flex flex-col lg:flex-row gap-4">
						<ConsumoEnergiaDiario
							dados={consumoEnergiaDiario}
							dadosDetalhado={
								consumoEnergiaDiarioDetalhado
							}
						/>
							<DemandaAtivaDiario
								dados={
									leituraEnergia
								}
								dadosDetalhado={
									consumoEnergiaDemandaAtivaDetalhado
								}
							/>
					</div>
					<div className="flex flex-col lg:flex-row gap-4">
						<SaladeMaquinasDiario
							dados={energia}
						/>
						<DemandaReativaDiario
							dados={leituraEnergia}
							dadosDetalhado={
								consumoEnergiaDemandaReativaDetalhado
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default MonitoramentoEnergia;
