import { useState, useEffect, useContext } from 'react';
import apiRegimes from '../../../../services/apis/apiRegimes';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiGrupo from '../../../../services/apis/apiGrupo';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaRegimes({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose,
}) {
	const { page } = useParams();
	const [nome, setNome] = useState('');
	const [pressaoReferencia, setPressaoReferencia] = useState(0);
	const [rangeMin, setRangeMin] = useState(0);
	const [rangeMax, setRangeMax] = useState(0);
	const [ajusteFino, setAjusteFino] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [listaRegimes, setListaRegimes] = useState([]);
	const [grupoId, setGrupoId] = useState(0);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [pagination, setPagination] = useState();
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [unidadeId, setUnidadeId] = useState(0);
	const { erro, sucesso } = useContext(ToastContext);
	const [listaUnidadesTabela, setListaUnidadesTabela] = useState(new Map());
	const [tipoRegime, setTipoRegime] = useState("");
	const [tiposRegime, setTiposRegime] = useState([]);
	
	const funcaoModal = funcaoModalController.funcaoModal;

	//GET REGIME
	useEffect(() => {
		apiRegimes.fetchApi(page).then((res) => {
			setListaRegimes(res.data.items);
			setPagination(res.data);
		});
		apiGrupo.fetchApi().then(res => {
			setListaGrupos(res.data);
		});
		apiEnum.getTiposRegime().then(res => {
			setTiposRegime(res.data);
		});
	}, [page]);


	useEffect(() => {
		grupoId !== undefined &&
			apiEmpresa.getByGrupoApi(grupoId).then(res => setListaEmpresas(res.data));
	}, [grupoId])

	useEffect(() => {
		empresaId !== undefined &&
			apiUnidade.getByEmpresaApi(empresaId).then(res => setListaUnidades(res.data));
	}, [empresaId])

	useEffect(() => {
		if (listaRegimes.length > 0) {
			setListaUnidadesTabela(new Map());
			listaRegimes.forEach(elemento => {
				apiUnidade.getById(elemento.unidadeId).then(res => {
					const unidade = res.data;
					setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
				})
			});
		}
	}, [listaRegimes]);

	//DELETE REGIME
	function deletar(id) {
		apiRegimes
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Regime delatado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar regime!');
			});
	}

	function limparInputs() {
		setNome('');
		setPressaoReferencia(0);
		setRangeMin(0);
		setRangeMax(0);
		setAjusteFino(0);
		atualizarLista();
	}

	function enviar(e) {
		e.preventDefault();
		const regime = {
			nome: nome,
			pressaoReferencia: parseFloat(pressaoReferencia),
			rangeMin: parseFloat(rangeMin),
			rangeMax: parseFloat(rangeMax),
			ajusteFino: parseFloat(ajusteFino),
			tipoRegime: tipoRegime,
			unidadeId: parseInt(e.target.unidadeId.value),
		};

		if (funcaoModal === 'Adicionar') {
			apiRegimes
				.postApi(regime)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Regime adicionado com sucesso!');
					onClose();
				})
				.catch((error) => {
					erro('Erro ao adicionar regime!');
					console.error(error.message);
				});
			} else if (funcaoModal === 'Editar') {
				apiRegimes
				.putApi(idEditar, regime)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Regime editado com sucesso!');
					onClose();
				})
				.catch((error) => {
					erro('Erro ao editar regime!');
					console.error(error.message);
				});
		}
	}


	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaRegimes.map(
								(regime, i) =>
									regime.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) &&
									(
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													regime.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getParentMap(listaUnidadesTabela, regime.unidadeId)?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const unidadeId = regime.unidadeId;
																const empresaId = getParentMap(listaUnidadesTabela, unidadeId)?.empresaId;
																apiEmpresa.getById(empresaId).then(res => {
																	setGrupoId(
																		res.data.grupoId
																	);
																});
																setIdEditar(
																	regime.id
																);
																setNome(
																	regime.nome
																);
																setRangeMin(
																	regime.rangeMin
																);
																setRangeMax(
																	regime.rangeMax
																);
																setPressaoReferencia(
																	regime.pressaoReferencia
																);
																setAjusteFino(
																	regime.ajusteFino
																);

																setEmpresaId(
																	empresaId
																);
																setUnidadeId(
																	unidadeId
																);
																setTipoRegime(
																	regime.tipoRegime.replaceAll("_", " ")
																);

																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	regime.nome
																);
																setIdRemover(
																	regime.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&

						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Regime
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-y-auto">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do regime"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* PRESSAO MINIMA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="pressaoMinima"
								className="text-[18px] mb-2 pl-1"
							>
								Pressão mínima
							</label>
							<input
								id="pressaoMinima"
								name="pressaoMinima"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={rangeMin}
								onChange={(e) =>
									setRangeMin(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* PRESSAO MAXIMA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="pressaoMaxima"
								className="text-[18px] mb-2 pl-1"
							>
								Pressão máxima
							</label>
							<input
								id="pressaoMaxima"
								name="pressaoMaxima"
								type="number"
								placeholder="pressão máxima"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={rangeMax}
								onChange={(e) =>
									setRangeMax(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* PRESSAO REFERENCIA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="pressaoReferencia"
								className="text-[18px] mb-2 pl-1"
							>
								Pressão Referência
							</label>
							<input
								id="pressaoReferencia"
								name="pressaoReferencia"
								type="number"
								placeholder="pressão referência"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={pressaoReferencia}
								onChange={(e) =>
									setPressaoReferencia(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* AJUSTE FINO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="ajusteFino"
							>
								Ajuste Fino
							</label>
							<input
								id="ajusteFino"
								name="ajusteFino"
								type="number"
								placeholder="ajuste fino"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={ajusteFino}
								onChange={(e) =>
									setAjusteFino(
										e.target.value
									)
								}
								required
							/>
						</div>
							{/* FILTRO GRUPO */}
							<div className="flex flex-col justify-end">
							<label
								htmlFor="tipoRegime"
								className="text-[18px] mb-2 pl-1"
							>
								Tipo de regime
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="tipoRegime"
								id="tipoRegime"
								required
								value={tipoRegime}
								onChange={(e) =>
									setTipoRegime(
											e.target.value
									)
								}
							>
								<option label="   "></option>
								{tiposRegime.filter(tipoRegime => tipoRegime !== 'Condensação').map(
									(tipoRegime, i) => (
										<option
											value={
												tipoRegime
											}
											key={i}
										>
											{tipoRegime}
										</option>
									)
								)}
							</select>
						</div>
						{/* FILTRO GRUPO */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="grupoId"
								className="text-[18px] mb-2 pl-1"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(grupo, i) => (
										<option
											value={
												grupo.id
											}
											key={i}
										>
											{grupo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* FILTRO EMPRESA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="empresaId"
								className="text-[18px] mb-2 pl-1"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADE ID */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="unidadeId"
								className="text-[18px] mb-2 pl-1"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
