import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../../common/CardLayout';
import NoDataAlert from '../../common/returns/NoDataAlert';
import ChartViewDefault from './charts/ChartBarDefault';
import ChartCapacidades from './charts/ChartCapacidades';
import ChartEECondensadores2 from './charts/ChartEECondensadores';
import apiCondensadores from '../../../services/apis/apiCondensadores';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import ChartMixedDefault from './charts/ChartMixedDefault';
import StatusCondensadores from './ListCondensadores/StatusCondensadores';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

export default function ListCondesadores({ setCapacidadeCondensadores, alarmes }) {
	const [hubConnection, setHubConnection] = useState();
	const [condensadores, setCondensadores] = useState([]);
	const [leiturasAtuais, setLeiturasAtuais] = useState();
	const unidadeId = getCurrentUnidadeId();

	const [key, setKey] = useState(0);

	const fetchData = useCallback(async () => {
		try {
			const condensadores = (await apiCondensadores.getByUnidadeId(unidadeId)).data;
			setCondensadores(condensadores); 
			
		} catch (error) {
			console.error(
				`Erro fetching condensadores [SobreUnidade - Condensadores]: ${error}`
			);
		}
	}, [unidadeId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);


	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(
				`${apiLink}LeituraCondensadorHub`, unidadeId
			)
		);
	}, [unidadeId]);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					return hubConnection.invoke('Connect', unidadeId);
				})
				.then(() => {
					hubConnection.on(
						'AtualizarDadosCondensador',
						(message) => {
							setLeiturasAtuais(message);
						}
					);
				})
				.catch((err) =>
					console.log(
						'Error while establishing connection [SobreUnidade - Condensadores]:',
						err
					)
				);
		}
	}, [hubConnection, unidadeId]);

	useEffect(() => {
		if (leiturasAtuais) {
			const condensadoresAtualizados = condensadores.map((condensador) => {
				const leitura = leiturasAtuais.find(
					(l) => l.condensadorId === condensador.id
				);
				if (leitura) {
					return {
						...condensador,
						leiturasCondensadores: [leitura],
					};
				}
				return condensador;
			});
			setCondensadores(condensadoresAtualizados);
			setKey((prevKey) => prevKey + 1);
			setLeiturasAtuais(null);
		}
	}, [leiturasAtuais, condensadores]);

	useEffect(() => {
		if(condensadores.length > 0) {

			const leituras = condensadores.map(l => l.leiturasCondensadores[0]);
			const somaCapacidades = leituras.reduce((acc, leitura) => {
				if(leitura?.capacidadeMedida) {
					return acc + leitura.capacidadeMedida;
				}
				return acc;
			}, 0);
			setCapacidadeCondensadores(somaCapacidades);
		}

	},[condensadores, setCapacidadeCondensadores]);

	return (

		<>
			{/* <StatusCondensadores
				leituraCondensadores={wsDadosGraficos}
				condensacao={condensacao}
			/> */}
			<div className="flex w-full flex-wrap lg:flex-nowrap gap-4" key={key}>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Capacidade Condensadores
						</h2>
						<div className="flex w-full items-center">
							{condensadores.length > 0 ? (
								<ChartCapacidades
									chartDataProps={condensadores.filter(
										(d) => d.leiturasCondensadores[0]
									)}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Indice Eficiência Condensadores
						</h2>
						<div className="flex w-full items-center">
							{condensadores.length > 0 ? (
								<ChartEECondensadores2
									chartDataProps={condensadores.filter(
										(d) => d.leiturasCondensadores[0]
									)}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div>
			<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							TBU Admissão do Ar
						</h2>
						<div className="flex w-full items-center">
							{condensadores.length > 0 ? (
								<ChartViewDefault
									chartDataProps={condensadores.filter(
										(d) => d.leiturasCondensadores[0]
									)}
									typeChart={'tbuAdmissao'}
									nameSerie={' TBU ( °C )'}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Ganho energético do Ar
						</h2>
						<div className="flex w-full items-center">
							{condensadores.length > 0 ? (
								<ChartMixedDefault
									chartDataProps={condensadores.filter(
										(d) => d.leiturasCondensadores[0]
									)}
									typeChart={
										'ganhoEnergetico'
									}
									nameSerie={
										'Ganho Energético  ( kJ/Kg )'
									}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Umidade Relativa
						</h2>
						<div className="flex w-full items-center">
							{condensadores.length > 0 ? (
								<ChartMixedDefault 
									chartDataProps={condensadores.filter(
										(d) => d.leiturasCondensadores[0]
									)}
									typeChart={
										'umidadeRelativaSaida'
									}
									nameSerie={
										'Umidade Relativa  ( % )'
									}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div>
			<div className="flex w-full h-full">
				<StatusCondensadores
					condensadores={condensadores}
					alarmes={alarmes}
				/>
			</div>
			{/* <div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
				<CardLayout>
					<div className="flex w-full flex-col justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Vazao Volumetrica do Ar
						</h2>
						<p className="text-sm font-semibold text-white drop-shadow-1xl">
							Vazao ( m³/s )
						</p>
						<div className="flex w-full items-center px-0 md:px-8">
							{dadosGraficos.length > 0 ? (
								<ChartViewLineVazoes
									vazoes={vazoes}
									condensadores={condensadores}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div> */}

		</>
	);
}

