import React from 'react';
import IconStatus from './IconStatus';
import Table from '../../common/table';
import { format } from 'date-fns';

const TableAlarmRegime = ({ listaAlarmesRegime }) => {

	function getDuracao(dataInicio) {
		const ms = new Date() - new Date(dataInicio);
		let hours = parseInt(ms / (1000 * 60 * 60));
		let minutes = parseInt((ms % (1000 * 60 * 60)) / 60000);

		return formatNumber(hours) + 'h' + formatNumber(minutes);
	}
	function formatNumber(num) {
		return num.toString().length === 1 ? '0' + num : num;
	}
	return (
		<>
			<div className="w-full max-h-36">
				<Table.Root>
					<Table.Head>
						<Table.Header>Data/Hora</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Alarme</Table.Header>
						<Table.Header>Valor</Table.Header>
						<Table.Header>Duração</Table.Header>
						<Table.Header>Status</Table.Header>
					</Table.Head>

					<Table.Body>
						{listaAlarmesRegime.length > 0 ? (
							listaAlarmesRegime?.map(
								(regimeAlarme, i) => (
									<Table.Row
										key={i}
										className="py-2"
									>
										<Table.Data>
											{format(
												regimeAlarme.dataHoraInicio, 'dd.MM, HH:mm'
											)}
										</Table.Data>
										<Table.Data>
											{
												regimeAlarme.equipamentoNome
											}
										</Table.Data>
										<Table.Data>
											{
												regimeAlarme.descricao
											}
										</Table.Data>
										<Table.Data>
											{regimeAlarme.valor.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)}
										</Table.Data>
										<Table.Data>
											{getDuracao(
												regimeAlarme.dataHoraInicio
											)}
										</Table.Data>
										<Table.Data>
											<IconStatus status="warning" />
										</Table.Data>
									</Table.Row>
								)
							)
						) : (
							<Table.Row>
								<Table.Data
									span={6}
									className="text-secondary"
								>
									<br />
									Nenhum alarme ativo
								</Table.Data>
							</Table.Row>
						)}
					</Table.Body>
				</Table.Root>
			</div>
		</>
	);
};

export default TableAlarmRegime;
