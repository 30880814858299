import CardLayout from '../../common/CardLayout';
import TitleCardSimple from '../../common/TitleCardSimple';
import CardPeriodoMedido from './CardPeriodoMedido';

import ChartAreaDefault from './charts/ChartAreaDefault';


const DemandaReativaDiario = ({ dados, dadosDetalhado }) => {

	const valores = dados.map((elemento) => elemento.demandaReativa);

	const horas = dados.map((elemento) => {
		const horaFormatada = new Date(elemento.dataHora);

		const horaString = horaFormatada.toLocaleTimeString([], {
			hour: '2-digit',
			hour12: false,
			minute: '2-digit',
		});
		return `${horaString}`;
	});

	const DemandaReativaData = {
		date: horas,
		valor: valores,
	};

	return (
		<>
			<CardLayout>
				<div className="flex justify-center h-full flex-col items-center px-4">
					<TitleCardSimple>
						Demanda reativa{' '}
						<span className="text-accent">(</span>
						diário
						<span className="text-accent">)</span>
					</TitleCardSimple>
					<div className="flex justify-center items-center">
						<CardPeriodoMedido
							ponta={dadosDetalhado?.ponta.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPCapacitivo={dadosDetalhado?.foraPontaCapacitivo.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPIndutivo={dadosDetalhado?.foraPontaIndutivo.toLocaleString(
								'pt-BR'
							)}
							dateFPCapacitivo={
								dadosDetalhado?.data
							}
							dateFPIndutivo={dadosDetalhado?.data}
							datePonta={dadosDetalhado?.data}
							unidade={'kVAr'}
						/>
					</div>
					<ChartAreaDefault
						typeChart="demandaReativa"
						nameSerie="Demanda Reativa ( kVAr )"
						chartDataProps={DemandaReativaData}
					/>
				</div>
			</CardLayout>
		</>
	);
};

export default DemandaReativaDiario;
