import { useState, useEffect, useContext } from 'react';
import apiRegimes from '../../../../services/apis/apiRegimes';
import apiCompressores from '../../../../services/apis/apiCompressores';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import PaginationBar from '../../../../components/common/PaginationBar';
import { useParams } from 'react-router-dom';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModeloCompressor from '../../../../services/apis/apiModeloCompressor';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';
import { getParentMap } from '../../../../funcs/getParent';
import apiTipoOperacaoCompressores from '../../../../services/apis/apiTipoOperacaoCompressores';

export default function ListaCompressores({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose
}) {
	const [nome, setNome] = useState('');
	const [fatorAjustePotencia, setFatorAjustePotencia] = useState(1);
	const [fatorAjusteCapacidade, setFatorAjusteCapacidade] = useState(1);
	const [potenciaNominal, setPotenciaNominal] = useState(0);
	const [capacidadeNominal, setCapacidadeNominal] = useState(0);
	const [fatorSobreCarga, setFatorSobreCarga] = useState(0);
	const [fatorCargaParcialMinima, setFatorCargaParcialMinima] = useState(0);
	const [setPointTemperaturaDescargaMinima, setSetPointTemperaturaDescargaMinima] = useState(0);
	const [setPointTemperaturaDescargaMaxima, setSetPointTemperaturaDescargaMaxima] = useState(0);
	const [tensao, setTensao] = useState(0);
	const [statusDesligado, setStatusDesligado] = useState(0);

	const [pagination, setPagination] = useState();
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaRegimes, setListaRegimes] = useState([]);
	const [listaModelos, setListaModelos] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaTipoOperacaoCompressores, setListaTipoOperacaoCompressores] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaRegimesTabela, setListaRegimesTabela] = useState(new Map());
	const [listaUnidadeTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaEmpresaTabela, setListaEmpresaTabela] = useState(new Map());

	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const { page } = useParams();

	const [listaCompressores, setListaCompressores] = useState([]);
	const [marcaId, setMarcaId] = useState(0);
	const [grupoId, setGrupoId] = useState(0)
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [modeloId, setModeloId] = useState(0);
	const [tipoOperacaoCompressorId, setTipoOperacaoCompressorId] =
		useState(0);
	const [regimeSuccaoId, setRegimeSuccao] = useState(0);
	const [regimeDescargaId, setRegimeDescargaId] = useState(0);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);
	//GET COMPRESSORES

	function limparInputs() {
		setNome('');
		setFatorAjusteCapacidade(1);
		setFatorAjustePotencia(1);
		setMarcaId(0);
		setUnidadeId(0);
		setModeloId(0);
		setTipoOperacaoCompressorId(0);
		setRegimeDescargaId(0);
		setRegimeSuccao(0);
		setEmpresaId(0);
	}
	useEffect(() => {
		apiCompressores.fetchApi(page).then((res) => {
			setListaCompressores(res.data.items);
			setPagination(res.data);
		});
		apiMarca.fetchApi().then(res => {
			setListaMarcas(res.data);
		});
		apiGrupo.fetchApi().then(res => {
			setListaGrupos(res.data);
		}, []);
	}, [page]);

	useEffect(() => {
		if (listaCompressores.length > 0) {
			listaCompressores.forEach(elemento => {
				apiRegimes.getById(elemento.regimeSuccaoId).then(res => {
					const regime = res.data;
					setListaRegimesTabela(map => new Map(map.set(regime.id, regime)));
				});
				apiRegimes.getById(elemento.regimeDescargaId).then(res => {
					const regime = res.data;
					setListaRegimesTabela(map => new Map(map.set(regime.id, regime)));
				});
			});
		}
	}, [listaCompressores]);

	useEffect(() => {
		if (listaRegimesTabela.size > 0) {
			listaRegimesTabela.forEach(elemento => {
				apiUnidade.getById(elemento.unidadeId).then(res => {
					const unidade = res.data;
					setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
				});
			});
		}
	}, [listaRegimesTabela]);

	useEffect(() => {
		if (listaUnidadeTabela.size > 0) {
			listaUnidadeTabela.forEach(elemento => {
				apiEmpresa.getById(elemento.empresaId).then(res => {
					const empresa = res.data;
					setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
				});
			});
		}
	}, [listaUnidadeTabela]);

	useEffect(() => {
		grupoId !== undefined &&
			!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then(res => {
				setListaEmpresas(res.data);
			})
	}, [grupoId])

	useEffect(() => {
		empresaId !== undefined &&
			!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then(res => {
				setListaUnidades(res.data);
			}, [empresaId]);
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== undefined &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then(res => {
				setListaRegimes(res.data);
			});
	}, [unidadeId]);

	useEffect(() => {
		marcaId !== undefined &&
			!isNaN(marcaId) &&
			apiModeloCompressor.getByMarcaId(marcaId).then(res => {
				setListaModelos(res.data);
			})
	}, [marcaId]);

	useEffect(() => {
		modeloId !== undefined &&
			modeloId !== 0 &&
			!isNaN(modeloId) &&
			apiTipoOperacaoCompressores.getByModeloId(modeloId).then(res => {
				setListaTipoOperacaoCompressores(res.data);
			});
	}, [modeloId]);

	//DELETE COMPRESSOR
	function deletar(id) {
		apiCompressores
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Compressor deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar compressor!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const compressor = {
			nome: nome,
			fatorAjustePotencia: parseFloat(fatorAjustePotencia),
			fatorAjusteCapacidade: parseFloat(fatorAjusteCapacidade),
			potenciaNominal: parseFloat(potenciaNominal),
			capacidadeNominal: parseFloat(capacidadeNominal),
			fatorSobreCarga: parseFloat(fatorSobreCarga),
			fatorCargaParcialMinima: parseFloat(fatorCargaParcialMinima),
			tensao: parseFloat(tensao),
			statusDesligado: parseInt(statusDesligado),
			setPointTemperaturaDescargaMinima: parseFloat(setPointTemperaturaDescargaMinima),
			setPointTemperaturaDescargaMaxima: parseFloat(setPointTemperaturaDescargaMaxima),
			regimeSuccaoId: parseInt(e.target.regimeSuccaoId.value),
			regimeDescargaId: parseInt(e.target.regimeDescargaId.value),
			modeloCompressorId: parseInt(e.target.modeloCompressorId.value),
			tipoOperacaoCompressorId: parseInt(e.target.tipoOperacaoCompressorId.value),
		};
		if (funcaoModal === 'Adicionar') {
			apiCompressores
				.postApi(compressor)
				.then((res) => {
					limparInputs();
					sucesso('Compressor adicionado com sucesso!');
					atualizarLista();
					onClose();
				})
				.catch((error) => {
					erro('Erro ao adicionar compressor!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiCompressores
				.putApi(idEditar, compressor)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Compressor editado com sucesso!');
					onClose();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar compressor!');
				});
		}
	}

	function getUnidadeByRegimeId(id) {
		const regime = getParentMap(listaRegimesTabela, id);
		const unidade = getParentMap(listaUnidadeTabela, regime?.unidadeId);
		return unidade;
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Regime Sucção
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Regime Descarga
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaCompressores.map(
								(compressor, i) =>
									compressor.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													compressor.nome
												}
											</td>
											<td className="pl-[10px] text-[20px]">
												{getParentMap(listaRegimesTabela, compressor.regimeSuccaoId)?.nome}
											</td>
											<td className="pl-[10px] text-[20px]">
												{getParentMap(listaRegimesTabela, compressor.regimeDescargaId)?.nome}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getUnidadeByRegimeId(compressor.regimeSuccaoId)?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const regimeSuccaoId = compressor.regimeSuccaoId;
																const regime = getParentMap(listaRegimesTabela, regimeSuccaoId);
																const unidade = getParentMap(listaUnidadeTabela, regime.unidadeId);
																const empresa = getParentMap(listaEmpresaTabela, unidade.empresaId);
																apiModeloCompressor.getById(compressor.modeloCompressorId).then(res => {
																	setMarcaId(
																		res.data.marcaId
																	);
																	setModeloId(
																		compressor.modeloCompressorId
																	);
																})
																setIdEditar(
																	compressor.id
																);
																setNome(
																	compressor.nome
																);
																setGrupoId(
																	empresa.grupoId
																);
																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);
																setRegimeSuccao(
																	regimeSuccaoId
																);
																setFatorAjusteCapacidade(
																	compressor.fatorAjusteCapacidade
																);
																setFatorAjustePotencia(
																	compressor.fatorAjustePotencia
																);
																setTipoOperacaoCompressorId(
																	compressor.tipoOperacaoCompressorId
																);
																setRegimeSuccao(
																	regimeSuccaoId
																);
																setRegimeDescargaId(
																	compressor.regimeDescargaId
																);
																setPotenciaNominal(
																	compressor.potenciaNominal
																);
																setCapacidadeNominal(
																	compressor.capacidadeNominal
																);
																setFatorSobreCarga(
																	compressor.fatorSobreCarga
																);
																setFatorCargaParcialMinima(
																	compressor.fatorCargaParcialMinima
																);
																setTensao(
																	compressor.tensao
																);
																setStatusDesligado(
																	compressor.statusDesligado
																);
																setSetPointTemperaturaDescargaMinima(
																	compressor.setPointTemperaturaDescargaMinima
																);
																setSetPointTemperaturaDescargaMaxima(
																	compressor.setPointTemperaturaDescargaMaxima
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	compressor.nome
																);
																setIdRemover(
																	compressor.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Compressor
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do compressor"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* FATOR AJUSTE POTENCIA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="fatorAjustePotencia"
							>
								Fator ajuste potência
							</label>
							<input
								id="fatorAjustePotencia"
								name="fatorAjustePotencia"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={fatorAjustePotencia}
								onChange={(e) =>
									setFatorAjustePotencia(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* FATOR AJUSTE CAPACIDADE */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="fatorAjusteCapacidade"
							>
								Fator ajuste capacidade
							</label>
							<input
								id="fatorAjusteCapacidade"
								name="fatorAjusteCapacidade"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={fatorAjusteCapacidade}
								onChange={(e) =>
									setFatorAjusteCapacidade(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* POTENCIA NOMINAL */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="potenciaNominal"
							>
								Potência nominal
							</label>
							<input
								id="potenciaNominal"
								name="potenciaNominal"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={potenciaNominal}
								onChange={(e) =>
									setPotenciaNominal(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* CAPACIDADE NOMINAL */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="capacidadeNominal"
							>
								Capacidade nominal
							</label>
							<input
								id="capacidadeNominal"
								name="capacidadeNominal"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={capacidadeNominal}
								onChange={(e) =>
									setCapacidadeNominal(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/*  FATOR SOBRE CARGA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="fatorSobreCarga"
							>
								Fator sobre carga
							</label>
							<input
								id="fatorSobreCarga"
								name="fatorSobreCarga"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={fatorSobreCarga}
								onChange={(e) =>
									setFatorSobreCarga(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* FATOR CARGA PARCIAL MINIMA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="fatorCargaParcialMinima"
							>
								Fator carga parcial mínima
							</label>
							<input
								id="fatorCargaParcialMinima"
								name="fatorCargaParcialMinima"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={fatorCargaParcialMinima}
								onChange={(e) =>
									setFatorCargaParcialMinima(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* SET POINT TEMPERATURA DESCARGA MINIMA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="setPointTemperaturaDescargaMinima"
							>
								Set point temperatura <br/>descarga mínima 
							</label>
							<input
								id="setPointTemperaturaDescargaMinima"
								name="setPointTemperaturaDescargaMinima"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={setPointTemperaturaDescargaMinima}
								onChange={(e) =>
									setSetPointTemperaturaDescargaMinima(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* SET POINT TEMPERATURA DESCARGA MAXIMA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="setPointTemperaturaDescargaMaxima"
							>
								Set point temperatura <br/>descarga máxima 
							</label>
							<input
								id="setPointTemperaturaDescargaMaxima"
								name="setPointTemperaturaDescargaMaxima"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={setPointTemperaturaDescargaMaxima}
								onChange={(e) =>
									setSetPointTemperaturaDescargaMaxima(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* TENSAO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="tensao"
							>
								Tensão
							</label>
							<input
								id="tensao"
								name="tensao"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={tensao}
								onChange={(e) =>
									setTensao(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* STATUS DELISGADO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="statusDesligado"
							>
								Status desligado
							</label>
							<input
								id="statusDesligado"
								name="statusDesligado"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={statusDesligado}
								onChange={(e) =>
									setStatusDesligado(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* MARCA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="marcaId"
							>
								Marca
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{listaMarcas.map((marca, i) => (
									<option
										value={marca.id}
										key={i}
									>
										{marca.nome}
									</option>
								))}
							</select>
						</div>
						{/* MODELO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="modeloCompressorId"
							>
								Modelo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="modeloCompressorId"
								id="modeloCompressorId"
								value={modeloId}
								required
								onChange={(e) =>
									setModeloId(
										parseInt(
											parseInt(
												e.target
													.value
											)
										)
									)
								}
							>
								<option label="   "></option>
								{listaModelos
									.map((modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									))}
							</select>
						</div>

						{/* TIPO OPERACAO COMPRESSOR */}

						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="tipoOperacaoCompressorId"
							>
								Tipo de operação
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="tipoOperacaoCompressorId"
								id="tipoOperacaoCompressorId"
								required
								value={tipoOperacaoCompressorId}
								onChange={(e) =>
									setTipoOperacaoCompressorId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaTipoOperacaoCompressores
									.map((operacao, i) => (
										<option
											value={
												operacao.id
											}
											key={i}
										>
											{
												operacao.nome.replaceAll("_"," ")
											}
										</option>
									))}
							</select>
						</div>
						{/* Grupos */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* REGIME SUCCAO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeSuccaoId"
							>
								Regime Sucção
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="regimeSuccaoId"
								id="regimeSuccaoId"
								required
								value={regimeSuccaoId}
								onChange={(e) =>
									setRegimeSuccao(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes
									.filter(
										(elemento) =>
											elemento.unidadeId ===
											unidadeId
									)
									.map((regime, i) => (
										<option
											value={
												regime.id
											}
											key={i}
										>
											{regime.nome}
										</option>
									))}
							</select>
						</div>
						{/* REGIME DESCARGA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeDescargaId"
							>
								Regime Descarga
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="regimeDescargaId"
								id="regimeDescargaId"
								value={regimeDescargaId}
								required
								onChange={(e) =>
									setRegimeDescargaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes
									.filter(
										(elemento) =>
											elemento.unidadeId ===
											unidadeId
									)
									.map((regime, i) => (
										<option
											value={
												regime.id
											}
											key={i}
										>
											{regime.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
