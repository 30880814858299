import CardLayout from '../../common/CardLayout';
import TitleCardSimple from '../../common/TitleCardSimple';

import ChartAreaDefault from './charts/ChartAreaDefault';

const SaladeMaquinasDiario = ({ dados }) => {

	const valores = dados.map((elemento) => elemento.potenciaSalaMaquinasKw);

	const horas = dados.map((elemento) => {
		const horaFormatada = new Date(elemento.dataHora); // hora, minuto, segundo

		const horaString = horaFormatada.toLocaleTimeString([], {
			hour: '2-digit',
			hour12: false,
			minute: '2-digit',
		});
		return `${horaString}`;
	});

	const SalaMaquinasData = {
		date: horas,
		valor: valores,
	};

	return (
		<>
			<CardLayout>
				<div className="flex justify-between flex-col items-center px-4 h-full">
					<TitleCardSimple>
						Sala de máquinas{' '}
						{/* <span className="text-accent">(</span>
						diário
						<span className="text-accent">)</span> */}
					</TitleCardSimple>
					{/* <div className="flex justify-center items-center flex-coltext-white text-sm lg:text-lg">
						<p className="py-2 flex items-center px-2 lg:text-lg">
							Acumulado total
							<TbSum className="h-5 w-5 pl-1" />
						</p>
						<p className="drop-shadow-1xl text-lg lg:text-2xl font-bold bg-gradient-to-r from-[#008000] via-[#00FF00] to-[#008000] inline-block text-transparent bg-clip-text">
							{SaladeMaquinasDiarioValues.accSalaMaquinasDiario.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}{' '}
							{SaladeMaquinasDiarioValues.unidade}
						</p>
					</div> */}
					{/* <div className="flex justify-center items-center">
						<CardPeriodoMedido
							ponta={SaladeMaquinasDiarioValues.accPontaSalaMaquinasDiario.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPCapacitivo={SaladeMaquinasDiarioValues.accFPCapacitivoSalaMaquinasDiario.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPIndutivo={SaladeMaquinasDiarioValues.accFPIndutivoSalaMaquinasDiario.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							dateFPCapacitivo={
								SaladeMaquinasDiarioValues.date
							}
							dateFPIndutivo={
								SaladeMaquinasDiarioValues.date
							}
							datePonta={
								SaladeMaquinasDiarioValues.date
							}
							unidade={
								SaladeMaquinasDiarioValues.unidade
							}
						/>
					</div> */}
					<ChartAreaDefault
						typeChart="salaMaquinas"
						nameSerie="Sala de Maquinas ( kW )"
						chartDataProps={SalaMaquinasData}
					/>
				</div>
			</CardLayout>
		</>
	);
};

export default SaladeMaquinasDiario;
