import { useState, useEffect, useContext } from 'react';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import { useParams } from 'react-router-dom';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';
import PaginationBar from '../../../../components/common/PaginationBar';
import { getParentMap } from '../../../../funcs/getParent';
import apiChillers from '../../../../services/apis/apiChiller';
import apiRegimes from '../../../../services/apis/apiRegimes';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaChillers({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose
}) {
	const [nome, setNome] = useState('');
	const [tipoProduto, setTipoProduto] = useState('');
	const [tipoMiudo, setTipoMiudo] = useState('');
	const [calorEspecifico, setCalorEspecifico] = useState(0);
	const [temperaturaReferenciaProduto, setTemperaturaReferenciaProduto] = useState(0);
	const [temperaturaReferenciaAgua, setTemperaturaReferenciaAgua] = useState(0);
	const [temperaturaEntradaProduto, setTemperaturaEntradaProduto] = useState(0);
	const [temperaturaSaidaProduto, setTemperaturaSaidaProduto] = useState(0);
	const [temperaturaEntradaAgua, setTemperaturaEntradaAgua] = useState(0);
	const [temperaturaSaidaAgua, setTemperaturaSaidaAgua] = useState(0);
	const [volumeAguaPorUnidade, setVolumeAguaPorUnidade] = useState(0);
	const [pesoProdutoUnitario, setPesoProdutoUnitario] = useState(0);
	const [pesoProdutoHora, setPesoProdutoHora] = useState(0);
	const [quantidadeFrango, setQuantidadeFrango] = useState(0);
	const [volumeAguaPorKg, setVolumeAguaPorKg] = useState(0);
	const [showFrangoInteiroForm, setShowFrangoInteiroForm] = useState(false);
	const [showMiudoForm, setShowMiudoForm] = useState(false);
	const [tiposProduto, setTiposProduto] = useState([]);
	const [tiposMiudo, setTiposMiudo] = useState([]);

	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [listaUnidadesTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaEmpresasTabela, setListaEmpresaTabela] = useState(new Map());
	const [listaRegimesTabela, setListaRegimesTabela] = useState(new Map());
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaRegimes, setListaRegimes] = useState([]);
	const [regimeId, setRegimeId] = useState(0);
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const [listaChillers, setListaChillers] = useState([]);
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);
	useEffect(() => {
		if (tipoProduto === "Frango inteiro") {
			setShowFrangoInteiroForm(true);
			setShowMiudoForm(false);
		} else if (tipoProduto === "Miúdos") {
			setShowFrangoInteiroForm(false);
			setShowMiudoForm(true);
		} else {
			setShowFrangoInteiroForm(false);
			setShowMiudoForm(false);
		}
	}, [tipoProduto]);

	function limparInputs() {
		setNome('');
		setEmpresaId(0);
		setUnidadeId(0);
	}

	useEffect(() => {
		apiChillers.fetchApi(page).then((res) => {
			setListaChillers(res.data.items);
			setPagination(res.data);
		});
		apiGrupo.fetchApi().then(res => {
			setListaGrupos(res.data);
		});
		apiEnum.getTiposProduto().then(res => {
			setTiposProduto(res.data);
		});
		apiEnum.getTiposMiudo().then(res => {
			setTiposMiudo(res.data);
		});
	}, [page]);

	useEffect(() => {
		grupoId !== undefined &&
			!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then(res => {
				setListaEmpresas(res.data);
			})
	}, [grupoId]);

	useEffect(() => {
		empresaId !== undefined &&
			!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then(res => {
				setListaUnidades(res.data);
			})
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== undefined &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then(res => {
				setListaRegimes(res.data);
			})
	}, [unidadeId]);

	useEffect(() => {
		listaChillers.length > 0 &&
			listaChillers.forEach(elemento => {
				apiRegimes.getById(elemento.regimeId).then(res => {
					const regime = res.data;
					setListaRegimesTabela(map => new Map(map.set(regime.id, regime)));
				});
			});
	}, [listaChillers]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
			listaRegimesTabela.forEach(elemento => {
				apiUnidade.getById(elemento.unidadeId).then(res => {
					const unidade = res.data;
					setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
				});
			});
	}, [listaRegimesTabela]);

	useEffect(() => {
		listaUnidadesTabela.size > 0 &&
			listaUnidadesTabela.forEach(elemento => {
				apiEmpresa.getById(elemento.empresaId).then(res => {
					const empresa = res.data;
					setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
				});
			});
	}, [listaUnidadesTabela]);

	function getParentsObjects(id) {
		const regime = getParentMap(listaRegimesTabela, id);
		const unidade = getParentMap(listaUnidadesTabela, regime?.unidadeId);
		const empresa = getParentMap(listaEmpresasTabela, unidade?.empresaId);

		return {
			unidade: unidade,
			empresa: empresa,
		}

	}

	function deletar(id) {
		apiChillers
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Chiller deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar Chiller!');
			});
	}

	function enviar(e) {
		e.preventDefault();
		let chiller;
		if (tipoProduto === "Frango inteiro") {
			chiller = {
				nome: nome,
				tipoProduto: tipoProduto,
				calorEspecifico: parseFloat(calorEspecifico),
				temperaturaReferenciaProduto: parseFloat(temperaturaReferenciaProduto),
				temperaturaReferenciaAgua: parseFloat(temperaturaReferenciaAgua),
				temperaturaEntradaProduto: parseFloat(temperaturaEntradaProduto),
				temperaturaSaidaProduto: parseFloat(temperaturaSaidaProduto),
				temperaturaEntradaAgua: parseFloat(temperaturaEntradaAgua),
				temperaturaSaidaAgua: parseFloat(temperaturaSaidaAgua),
				volumeAguaPorUnidade: parseFloat(volumeAguaPorUnidade),
				pesoProdutoUnitario: parseFloat(pesoProdutoUnitario),
				quantidadeFrango: parseFloat(quantidadeFrango),
				regimeId: parseFloat(regimeId),
			};

		} else {
			chiller = {
				nome: nome,
				tipoProduto: tipoProduto,
				tipoMiudo: tipoMiudo,
				calorEspecifico: parseFloat(calorEspecifico),
				temperaturaReferenciaProduto: parseFloat(temperaturaReferenciaProduto),
				temperaturaReferenciaAgua: parseFloat(temperaturaReferenciaAgua),
				temperaturaEntradaProduto: parseFloat(temperaturaEntradaProduto),
				temperaturaSaidaProduto: parseFloat(temperaturaSaidaProduto),
				pesoProdutoHora: parseFloat(pesoProdutoHora),
				volumeAguaPorKg: parseFloat(volumeAguaPorKg),
				regimeId: parseFloat(regimeId),
			}
		}
		if (funcaoModal === 'Adicionar') {
			apiChillers
				.postApi(chiller)
				.then((res) => {
					limparInputs();
					sucesso('Chiller adicionado com sucesso!');
					atualizarLista();
					onClose();
				})
				.catch((error) => {
					erro('Erro ao adicionar chiller!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiChillers
				.putApi(idEditar, chiller)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Chiller editado com sucesso!');
					onClose();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar chiller!');
				});
		}
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Regime
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaChillers.map(
								(chiller, i) =>
									chiller.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													chiller.nome
												}
											</td>
											<td className="pl-[10px] text-[20px]">
												{
													getParentMap(listaRegimesTabela, chiller.regimeId)?.nome
												}
											</td>

											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getParentsObjects(chiller.regimeId)?.unidade?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const { empresa, unidade } = getParentsObjects(chiller.regimeId)
																setIdEditar(
																	chiller.id
																);
																setTipoProduto(
																	chiller.tipoProduto.replaceAll("_", " ")
																);

																setNome(
																	chiller.nome
																);
																setGrupoId(
																	empresa.grupoId
																);

																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);

																setRegimeId(
																	chiller.regimeId
																);
																setTipoMiudo(
																	chiller.tipoMiudo.replaceAll("_", " ")
																);
																setCalorEspecifico(
																	chiller.calorEspecifico
																);

																setTemperaturaReferenciaProduto(
																	chiller.temperaturaReferenciaProduto
																);

																setTemperaturaReferenciaAgua(
																	chiller.temperaturaReferenciaAgua
																);

																setTemperaturaEntradaAgua(
																	chiller.temperaturaEntradaAgua
																);

																setTemperaturaSaidaAgua(
																	chiller.temperaturaSaidaAgua
																);

																setTemperaturaEntradaProduto(
																	chiller.temperaturaEntradaProduto
																);

																setTemperaturaSaidaProduto(
																	chiller.temperaturaSaidaProduto
																);
																setQuantidadeFrango(
																	chiller.quantidadeFrango
																);
																setPesoProdutoUnitario(
																	chiller.pesoProdutoUnitario
																);
																setVolumeAguaPorKg(
																	chiller.volumeAguaPorKg
																);
																setVolumeAguaPorUnidade(
																	chiller.volumeAguaPorUnidade
																);
																setPesoProdutoHora(
																	chiller.pesoProdutoHora
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	chiller.nome
																);
																setIdRemover(
																	chiller.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent className="max-w-0 min-w-[600px]">
				<form onSubmit={(e) => enviar(e)} className='w-[550px]'>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Chiller
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do chiller"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* TIPO PRODUTO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="tipoProduto"
							>
								Tipo de produto
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="tipoProduto"
								id="tipoProduto"
								required
								value={tipoProduto}
								onChange={(e) =>
									setTipoProduto(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{
									tiposProduto.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>
									))
								}
							</select>
						</div>

						{/* TIPO MIUDO */}
						<div className={`flex flex-col justify-end ${!showMiudoForm && "hidden"}`}>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="tipoMiudo"
							>
								Tipo de miúdo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="tipoMiudo"
								id="tipoMiudo"
								required={showMiudoForm}
								value={tipoMiudo}
								onChange={(e) =>
									setTipoMiudo(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{
									tiposMiudo.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>
									))
								}
							</select>
						</div>


						{/* TEMPERATURA REFERENCIA PRODUTO*/}
						<div className="flex flex-col justify-end">
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="temperaturaReferenciaProduto"
							>
								Temperatura referência produto
							</label>
							<input
								id="temperaturaReferenciaProduto"
								name="temperaturaReferenciaProduto"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaReferenciaProduto}
								onChange={(e) =>
									setTemperaturaReferenciaProduto(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* TEMPERATURA REFERENCIA AGUA*/}
						<div className="flex flex-col justify-end">
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="temperaturaReferenciaAgua"
							>
								Temperatura referência água
							</label>
							<input
								id="temperaturaReferenciaAgua"
								name="temperaturaReferenciaAgua"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaReferenciaAgua}
								onChange={(e) =>
									setTemperaturaReferenciaAgua(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* TEMPERATURA ENTRADA PRODUTO*/}
						<div className="flex flex-col justify-end">
							<label
								className="text-[12px] mb-2 pl-1"
								htmlFor="temperaturaEntradaProduto"
							>
								Temperatura de entrada do produto
							</label>
							<input
								id="temperaturaEntradaProduto"
								name="temperaturaEntradaProduto"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaEntradaProduto}
								onChange={(e) =>
									setTemperaturaEntradaProduto(
										e.target.value
									)
								}
								required
							/>
						</div>


						{/* TEMPERATURA SAIDA PRODUTO*/}
						<div className="flex flex-col justify-end">
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="temperaturaSaidaProduto"
							>
								Temperatura de saída do produto
							</label>
							<input
								id="temperaturaSaidaProduto"
								name="temperaturaSaidaProduto"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaSaidaProduto}
								onChange={(e) =>
									setTemperaturaSaidaProduto(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* TEMPERATURA ENTRADA AGUA*/}
						<div className={`flex flex-col justify-end ${!showFrangoInteiroForm && "hidden"}`}>
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="temperaturaEntradaAgua"
							>
								Temperatura de entrada da água
							</label>
							<input
								id="temperaturaEntradaAgua"
								name="temperaturaEntradaAgua"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaEntradaAgua}
								onChange={(e) =>
									setTemperaturaEntradaAgua(
										e.target.value
									)
								}
								required={showFrangoInteiroForm}
							/>
						</div>

						{/* TEMPERATURA SAIDA AGUA*/}
						<div className={`flex flex-col justify-end ${!showFrangoInteiroForm && "hidden"}`}>
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="temperaturaSaidaAgua"
							>
								Temperatura de saída da água
							</label>
							<input
								id="temperaturaSaidaAgua"
								name="temperaturaSaidaAgua"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={temperaturaSaidaAgua}
								onChange={(e) =>
									setTemperaturaSaidaAgua(
										e.target.value
									)
								}
								required={showFrangoInteiroForm}
							/>
						</div>

						{/* VOLUME AGUA POR UNIDADE*/}
						<div className={`flex flex-col justify-end ${!showFrangoInteiroForm && "hidden"}`}>
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="volumeAguaPorUnidade"
							>
								Volume de água por unidade
							</label>
							<input
								id="volumeAguaPorUnidade"
								name="volumeAguaPorUnidade"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={volumeAguaPorUnidade}
								onChange={(e) =>
									setVolumeAguaPorUnidade(
										e.target.value
									)
								}
								required={showFrangoInteiroForm}
							/>
						</div>

						{/* CALOR ESPECIFICO*/}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="calorEspecifico"
							>
								Calor específico
							</label>
							<input
								id="calorEspecifico"
								name="calorEspecifico"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={calorEspecifico}
								onChange={(e) =>
									setCalorEspecifico(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* PESO PRODUTO UNITARIO*/}
						<div className={`flex flex-col justify-end ${!showFrangoInteiroForm && "hidden"}`}>
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="pesoProdutoUnitario"
							>
								Peso de produto unitário
							</label>
							<input
								id="pesoProdutoUnitario"
								name="pesoProdutoUnitario"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={pesoProdutoUnitario}
								onChange={(e) =>
									setPesoProdutoUnitario(
										e.target.value
									)
								}
								required={showFrangoInteiroForm}
							/>
						</div>

						{/* PESO PRODUTO HORA*/}
						<div className={`flex flex-col justify-end ${!showMiudoForm && 'hidden'}`}>
							<label
								className="text-[13px] mb-2 pl-1"
								htmlFor="pesoProdutoHora"
							>
								Peso do produto por hora
							</label>
							<input
								id="pesoProdutoHora"
								name="pesoProdutoHora"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={pesoProdutoHora}
								onChange={(e) =>
									setPesoProdutoHora(
										e.target.value
									)
								}
								required={showMiudoForm}
							/>
						</div>

						{/* QUANTIDADE DE FRANGO*/}
						<div className={`flex flex-col justify-end ${!showFrangoInteiroForm && "hidden"}`}>
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="quantidadeFrango"
							>
								Quantidade de frango
							</label>
							<input
								id="quantidadeFrango"
								name="quantidadeFrango"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={quantidadeFrango}
								onChange={(e) =>
									setQuantidadeFrango(
										e.target.value
									)
								}
								required={showFrangoInteiroForm}
							/>
						</div>

						{/* VOLUME DE ÁGUA POR KG*/}
						<div className={`flex flex-col justify-end ${!showMiudoForm && "hidden"}`}>
							<label
								className="text-[14px] mb-2 pl-1"
								htmlFor="volumeAguaPorKg"
							>
								Volume de água por kg
							</label>
							<input
								id="volumeAguaPorKg"
								name="volumeAguaPorKg"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								value={volumeAguaPorKg}
								onChange={(e) =>
									setVolumeAguaPorKg(
										e.target.value
									)
								}
								required={showMiudoForm}
							/>
						</div>


						{/* GRUPOS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* REGIME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeId"
							>
								Regime
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[200px]"
								name="regimeId"
								id="regimeId"
								required
								value={regimeId}
								onChange={(e) =>
									setRegimeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes
									.map((regime, i) => (
										<option
											value={
												regime.id
											}
											key={i}
										>
											{regime.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
