import ListaUsuarios from "./ListaUsuario";
import "react-toastify/dist/ReactToastify.css";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";
export default function UsuarioForm() {

    return (
        <AlertDialogOnClose nome={"Usuário"} placeholder={"Insira o nome do usuário"}>
            <ListaUsuarios />
        </AlertDialogOnClose>
    )
} 