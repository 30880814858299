import { useState, useEffect, useContext, Fragment, useCallback } from 'react';
import apiRegimes from '../../../../services/apis/apiRegimes';
import apiCondensadores from '../../../../services/apis/apiCondensadores';
import { ToastContext } from '../../../../contexts/ToastContext';
import JanelasForm from './JanelasForm';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import MainButton from '../../../../components/common/MainButton';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModelo from '../../../../services/apis/apiModelo';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaCondensadores({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose
}) {
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();

	const [listaModelos, setListaModelos] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const [listaCondensadores, setListaCondensadores] = useState([]);
	const [regime, setRegime] = useState([]);
	const [numJanelas, setNumJanelas] = useState(0);
	const [janelasFormLista, setJanelasFormLista] = useState([
		{
			id: numJanelas,
			quantidade: 0,
			altura: 0,
			largura: 0,
		},
	]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaUnidadesTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaRegimesTabela, setListaRegimesTabela] = useState(new Map());
	const [listaEmpresasTabela, setListaEmpresasTabela] = useState(new Map());

	const [nome, setNome] = useState('');
	const [acionamento, setAcionamento] = useState('');
	const [tiposAcionamento, setTiposAcionamento] = useState([]);
	const [capacidadeNominal, setCapacidadeNominal] = useState(0);
	const [condutividade, setCondutividade] = useState(0);
	const [potenciaVentiladores, setPotenciaVentiladores] = useState(0);
	const [potenciaBombas, setPotenciaBombas] = useState(0);
	const [ph, setPh] = useState(0);
	const [offSetVelocidade, setOffSetVelocidade] = useState(0);
	const [marcaId, setMarcaId] = useState(0);
	const [modeloId, setModeloId] = useState(0);
	const [grupoId, setGrupoid] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [pagination, setPagination] = useState();
	const { page } = useParams();
	const [key, setKey] = useState(0);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso, aviso } = useContext(ToastContext);

	//GET CONDENSADOR

	function limparInputs() {
		setNome('');
		setCapacidadeNominal(0);
		setPotenciaVentiladores(0);
		setPotenciaBombas(0);
		setPh(0);
		setOffSetVelocidade(0);
		setModeloId(0);
		setMarcaId(0);
		setEmpresaId(0);
		setUnidadeId(0);
		setNumJanelas(0);
		setJanelasFormLista([]);
	}
	useEffect(() => {
		apiCondensadores
			.fetchApi(page)
			.then((res) => {
				setListaCondensadores(res.data.items);
				setPagination(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
		apiGrupo
			.fetchApi()
			.then((res) => {
				setListaGrupos(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
		apiMarca.fetchApi().then((res) => {
			setListaMarcas(res.data);
		});
		apiEnum.getAcionamentos().then((res) => {
			setTiposAcionamento(res.data);
		});
	}, [page]);

	useEffect(() => {
		grupoId !== undefined &&
			!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then((res) => {
				setListaEmpresas(res.data);
			});
	}, [grupoId]);

	useEffect(() => {
		empresaId !== undefined &&
			!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then((res) => {
				setListaUnidades(res.data);
			});
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== undefined &&
			unidadeId !== 0 &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then((res) => {
				const condensacaoFiltrado = res.data.filter(
					(elemento) =>
						elemento.tipoRegime === 'Condensação'
				);
				if (condensacaoFiltrado.length > 0) {
					setRegime(condensacaoFiltrado[0]);
				} else if (condensacaoFiltrado.length === 0) {
					aviso('Unidade não tem condensação cadastrada!');
				}
			});
	}, [unidadeId, aviso]);

	useEffect(() => {
		!isNaN(marcaId) &&
			apiModelo.fetchApi(marcaId, 'CONDENSADOR').then((res) => {
				setListaModelos(res.data);
			});
	}, [marcaId]);

	useEffect(() => {
		listaCondensadores.length > 0 &&
			listaCondensadores.forEach((elemento) => {
				apiRegimes.getById(elemento.regimeId).then((res) => {
					const regime = res.data;
					setListaRegimesTabela(
						(map) => new Map(map.set(regime.id, regime))
					);
				});
			});
	}, [listaCondensadores]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
			listaRegimesTabela.forEach((elemento) => {
				apiUnidade.getById(elemento.unidadeId).then((res) => {
					const unidade = res.data;
					setListaUnidadesTabela(
						(map) =>
							new Map(map.set(unidade.id, unidade))
					);
				});
			});
	}, [listaRegimesTabela]);

	useEffect(() => {
		listaUnidadesTabela.size > 0 &&
			listaUnidadesTabela.forEach((elemento) => {
				apiEmpresa.getById(elemento.empresaId).then((res) => {
					const empresa = res.data;
					setListaEmpresasTabela(
						(map) =>
							new Map(map.set(empresa.id, empresa))
					);
				});
			});
	}, [listaUnidadesTabela]);

	function getUnidadeByRegimeId(id) {
		const regime = getParentMap(listaRegimesTabela, id);
		const unidade = getParentMap(
			listaUnidadesTabela,
			regime?.unidadeId
		);
		return unidade;
	}

	const atualizarJanelas = useCallback(() => {
		setKey(key + 1);
	}, [key]);
	//DELETE CONDENSADOR
	function deletar(id) {
		apiCondensadores
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Condensador deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar condensador!');
			});
	}
	function enviar(e) {
		e.preventDefault();

		const condensador = {
			nome: nome,
			acionamento: acionamento,
			capacidadeNominal: parseFloat(capacidadeNominal),
			offSetVelocidade: parseFloat(offSetVelocidade),
			potenciaVentiladores: parseFloat(potenciaVentiladores),
			potenciaBombas: parseFloat(potenciaBombas),
			condutividade: parseFloat(condutividade),
			modeloId: modeloId,
			ph: parseFloat(ph),
			regimeId: regime.id,
			janelas: janelasFormLista,
		};

		if (funcaoModal === 'Adicionar') {
			apiCondensadores
				.postApi(condensador)
				.then((res) => {
					limparInputs();
					sucesso('Condensador adicionado com sucesso!');
					onClose();
				})
				.catch((error) => {
					erro('Erro ao adicionar condensador!');
					console.error(error.message);
				})
				.finally(() => {
					atualizarLista();
				});
		} else if (funcaoModal === 'Editar') {
			apiCondensadores
				.putApi(idEditar, condensador)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Condensador editado com sucesso!');
					onClose();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar condensador!');
				});
		}
	}

	function deletarJanela() {
		if (janelasFormLista.length === 1) {
			aviso('É preciso cadastrar pelo menos uma janela');
		} else {
			janelasFormLista.pop();
			setNumJanelas(numJanelas - 1);
			atualizarJanelas();
		}
	}

	function adicionarJanela() {
		setJanelasFormLista((current) => [
			...current,
			{
				id: numJanelas,
				quantidade: 0,
				altura: 0,
				largura: 0,
			},
		]);
		setNumJanelas(numJanelas + 1);
	}

	function criarJanelas(listaJanelas) {
		setJanelasFormLista([]);
		atualizarJanelas();
		if (
			listaJanelas !== undefined &&
			Object.keys(listaJanelas).length > 0
		) {
			listaJanelas.forEach((elemento, i) => {
				elemento['id'] = i;
				setJanelasFormLista((current) => [
					...current,
					elemento,
				]);
			});
		}
		setNumJanelas(
			listaJanelas === undefined
				? 0
				: Object.keys(listaJanelas).length
		);
	}

	function handleJanelas(janela) {
		try {
			const janelaIndex = janelasFormLista.findIndex((elemento) => {
				return elemento.id === janela.id;
			});

			let aux = [...janelasFormLista];
			aux[janelaIndex] = janela;
			setJanelasFormLista(aux);
		} catch (error) {
			return false;
		}
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaCondensadores.map(
								(condensador, i) =>
									condensador.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													condensador.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{
														getUnidadeByRegimeId(
															condensador.regimeId
														)
															?.nome
													}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																apiModelo
																	.getById(
																		condensador.modeloId
																	)
																	.then(
																		(
																			res
																		) => {
																			const modelo =
																				res.data;
																			setMarcaId(
																				modelo.marcaId
																			);
																			setModeloId(
																				modelo.id
																			);
																		}
																	);
																const unidade =
																	getUnidadeByRegimeId(
																		condensador.regimeId
																	);
																const empresa =
																	getParentMap(
																		listaEmpresasTabela,
																		unidade.empresaId
																	);

																setGrupoid(
																	empresa.grupoId
																);
																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);
																setIdEditar(
																	condensador.id
																);

																setNome(
																	condensador.nome
																);
																setCapacidadeNominal(
																	condensador.capacidadeNominal
																);
																setPh(
																	condensador.ph
																);
																setOffSetVelocidade(
																	condensador.offSetVelocidade
																);
																setCondutividade(
																	condensador.condutividade
																);
																setAcionamento(
																	condensador.acionamento.replaceAll(
																		'_',
																		' '
																	)
																);
																criarJanelas(
																	condensador.janelas
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	condensador.nome
																);
																setIdRemover(
																	condensador.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{pagination !== undefined && (
						<PaginationBar pagination={pagination} />
					)}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-500 p-2 rounded-md hover:bg-red-600 hover:cursor-pointer"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Condensador
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome (tag)
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do condensador"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>

						{/* ACIONAMENTO */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="acionamento"
							>
								Acionamento
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="acionamento"
								id="acionamento"
								required
								value={acionamento}
								onChange={(e) =>
									setAcionamento(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{tiposAcionamento.map(
									(tipo, i) => (
										<option
											key={i}
											value={tipo}
										>
											{tipo}
										</option>
									)
								)}
							</select>
						</div>

						{/* CAPCIDADE NOMINAL */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="capacidadeNominal"
								className="text-[13px] mb-2 pl-1"
							>
								Capacidade nominal (Kcal/ h)
							</label>
							<input
								id="capacidadeNominal"
								name="capacidadeNominal"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={capacidadeNominal}
								onChange={(e) =>
									setCapacidadeNominal(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* CONDUTIVIDADE */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="condutividade"
								className="text-[18px] mb-2 pl-1"
							>
								Condutividade
							</label>
							<input
								id="condutividade"
								name="condutividade"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={condutividade}
								onChange={(e) =>
									setCondutividade(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* OFFSET VELOCIDADE */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="offSetVelocidade"
								className="text-[18px] mb-2 pl-1"
							>
								Offset velocidade
							</label>
							<input
								id="offSetVelocidade"
								name="offSetVelocidade"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={offSetVelocidade}
								onChange={(e) =>
									setOffSetVelocidade(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* PH */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="ph"
								className="text-[18px] mb-2 pl-1"
							>
								PH
							</label>
							<input
								id="ph"
								name="ph"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={ph}
								onChange={(e) =>
									setPh(e.target.value)
								}
								required
							/>
						</div>

						{/* MARCA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="marcaId"
								className="text-[18px] mb-2 pl-1"
							>
								Marca
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaMarcas.map((marca, i) => (
									<option
										value={marca.id}
										key={i}
									>
										{marca.nome}
									</option>
								))}
							</select>
						</div>
						{/* MODELO */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="modeloId"
								className="text-[18px] mb-2 pl-1"
							>
								Modelo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="modeloId"
								id="modeloId"
								value={modeloId}
								required
								onChange={(e) =>
									setModeloId(
										parseInt(
											parseInt(
												e.target
													.value
											)
										)
									)
								}
							>
								<option label="   "></option>
								{listaModelos.map(
									(modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* Grupos */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="grupoId"
								className="text-[18px] mb-2 pl-1"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoid(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="empresaId"
								className="text-[18px] mb-2 pl-1"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="unidadeId"
								className="text-[18px] mb-2 pl-1"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* JANELAS */}
						<div id="janelas-block" className="w-full">
							<Fragment key={key}>
								{janelasFormLista.map(
									(elemento, i) => (
										<JanelasForm
											key={i}
											numJanelaId={
												elemento.id
											}
											deletarJanela={
												deletarJanela
											}
											quantidade={
												elemento.quantidade
											}
											altura={
												elemento.altura
											}
											largura={
												elemento.largura
											}
											handleJanelas={
												handleJanelas
											}
										/>
									)
								)}
							</Fragment>
						</div>

						{/* ADICIONAR JANELA */}
						<div
							className="fkex flex-col w-[300px] h-[35px] mb-2"
							onClick={() => adicionarJanela()}
						>
							<MainButton type="button">
								Adicionar Janela
							</MainButton>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
