import { format } from "date-fns";
import CardLayout from "../common/CardLayout";
import NoDataAlert from "../common/returns/NoDataAlert";
import Table from "../common/table";

export default function ProducaoDiariaTable({ producaoDiaria }) {
    const criarDataSemFuso = (dataOriginal) => {
        const [ano, mes, dia] = dataOriginal.split('-'); // Supondo que a data recebida seja "YYYY-MM-DD"
        return new Date(ano, mes - 1, dia); // Cria a data no fuso horário local sem ajustar
    };
    return (
        <div className="p-2">

                <CardLayout>
                {
                    producaoDiaria.length === 0 ? <NoDataAlert/> :
                    <Table.Root>
                        <Table.Head>
                            <Table.Header>
                                Data
                            </Table.Header>
                            <Table.Header>
                                Produção mercado interno (kg)
                            </Table.Header>
                            <Table.Header>
                                Produção mercado externo (kg)
                            </Table.Header>
                            <Table.Header>
                                produção total (kg)
                            </Table.Header>
                            <Table.Header>
                                Indicador Diário (kWh / ton)
                            </Table.Header>
                        </Table.Head>
                        <Table.Body>
                            {
                                producaoDiaria.map((p) => (
                                    <Table.Row key={p.id}>
                                        <Table.Data className={"text-[16px]"}>
                                            {format(criarDataSemFuso(p.data), "dd/MM/yyyy")}
                                        </Table.Data>
                                        <Table.Data className={"text-[16px]"}>
                                            {
                                                p.producaoMercadoInterno.toLocaleString('pt-BR', {
                                                    maximumFractionDigits: 2,
                                                })
                                            }
                                        </Table.Data>
                                        <Table.Data className={"text-[16px]"}>
                                            {
                                                p.producaoMercadoExterno.toLocaleString('pt-BR', {
                                                    maximumFractionDigits: 2,
                                                })
                                            }
                                        </Table.Data>
                                        <Table.Data className={"text-[16px]"}>
                                            {
                                                p.producaoTotal.toLocaleString('pt-BR', {
                                                    maximumFractionDigits: 2,
                                                })
                                            }
                                        </Table.Data>
                                        <Table.Data className={"text-[16px]"}>
                                            {
                                                p.indicadorDiario.toLocaleString('pt-BR', {
                                                    maximumFractionDigits: 0,
                                                })
                                            }
                                        </Table.Data>
                                    </Table.Row>
                                ))
                            }
                        </Table.Body>
                    </Table.Root>
                }
            </CardLayout>
        </div>
    )
}