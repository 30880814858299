
import ListaChillers from "./ListaChiller";
import AlertDialogOnClose from "../../../../components/admin/AlertDiologOnClose";
export default function ChillerForm() {

    return (
        <AlertDialogOnClose nome={"Chiller"} placeholder={"Insira o nome do chiller"} >
                <ListaChillers />
        </AlertDialogOnClose>
    )
} 