import { useCallback, useContext, useEffect, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import Table from "../../components/common/table";
import DatePickerGetData from "../../components/common/DatePickerGetData";
import apiRelatorio from "../../services/apis/apiRelatorio";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { getClientTimeZoneOffset } from "../../funcs";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import apiUnidade from "../../services/apis/apiUnidade";
import { ToastContext } from "../../contexts/ToastContext";
import NoDataAlert from "../../components/common/returns/NoDataAlert";


export default function Evento() {

    const [dados, setDados] = useState([]);
    const unidadeId = getCurrentUnidadeId();
    const [unidade, setUnidade] = useState({});
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
    const { erro } = useContext(ToastContext);   

    useEffect(() => {
        apiUnidade.getById(unidadeId).then(res => {
            setUnidade(res.data);
        }).catch(error => {
            console.error('Erro ao buscar os dados:', error);
        })
    },[unidadeId]);

    const onSearch = useCallback(async (date) => {
        const dataFormatada = date?.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        const timeZoneOffset = getClientTimeZoneOffset();

        const fusoData = {
            data: dataFormatada,
            TimeZoneNumber: timeZoneOffset
        }

        const res = await apiRelatorio.relatorioAlarmes(unidadeId, fusoData)
        setDados(res.data);
    }, [unidadeId]);


    const gerarRelatorio = async (tempSelectedDateDe, tempSelectedDateAte) => {
        try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: tempSelectedDateDe,
                dataFim: tempSelectedDateAte,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false 
			}).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.relatorioAlarmesExcel(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Alarmes_${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
    }


    const getDate = (date) => {
        const formattedDate = format(date, 'dd/MM/yyyy HH:mm:ss:MM', { locale: ptBR });

        if(formattedDate.startsWith("01/01/0001")) {
            return "Alarme ativo";
        }

        return formattedDate;

    }

    return <>

        <DatePickerGetData onSearch={onSearch} gerarRelatorio={gerarRelatorio} loading={isLoadingRelatorio}/>

        <CardLayout>
            {
                dados.length === 0 ? <NoDataAlert/> :
                <Table.Root>
                    <Table.Head>
                        <Table.Header>
                            Equipamento
                        </Table.Header>
                        <Table.Header>
                            Descrição
                        </Table.Header>
                        <Table.Header>
                            Valor
                        </Table.Header>
                        <Table.Header>
                            Data hora início
                        </Table.Header>
                        <Table.Header>
                            Data hora fim
                        </Table.Header>
                    </Table.Head>
                    <Table.Body>
                        {
                            dados.map((alarme) => (
                                <Table.Row key={alarme.id}>
                                    <Table.Data>
                                        {alarme.equipamentoNome}
                                    </Table.Data>
                                    <Table.Data>
                                        {alarme.descricao}
                                    </Table.Data>
                                    <Table.Data>
                                        {alarme.valor}
                                    </Table.Data>
                                    <Table.Data>
                                        {
                                            getDate(alarme.dataHoraInicio)   
                                        }
                                    </Table.Data>
                                    <Table.Data>
                                        {
                                            getDate(alarme.dataHoraFim)   
                                        }
                                    </Table.Data>
                                </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table.Root>
            }
        </CardLayout>
    </>


}