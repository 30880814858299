import { useState, useEffect, useContext } from 'react';
import apiRegimes from '../../../../services/apis/apiRegimes';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiBombas from '../../../../services/apis/apiBomba';
import apiCondensadores from '../../../../services/apis/apiCondensadores';
import { useParams } from 'react-router-dom';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModelo from '../../../../services/apis/apiModelo';
import PaginationBar from '../../../../components/common/PaginationBar';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaBomba({
	atualizarLista,
	pesquisar,
	funcaoModalController,
	onClose
}) {
	const [nome, setNome] = useState('');
	const [potenciaNominal, setPotenciaNominal] = useState(0);
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [listaModelos, setListaModelos] = useState([]);
	const [listaRegimesTabela, setListaRegimeTabela] = useState(new Map());
	const [listaCondensadoresTabela, setListaCondensadoresTabela] = useState(new Map());
	const [listaUnidadeTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaEmpresaTabela, setListaEmpresaTabela] = useState(new Map());
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [pagination, setPagination] = useState();
	const { page } = useParams();

	const [listaBomba, setListaBomba] = useState([]);
	const [marcaId, setMarcaId] = useState(0);
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [modeloId, setModeloId] = useState(0);
	const [regime, setRegime] = useState();
	const [tipoAcionamento, setTipoAcionamento] = useState('');
	const [tiposAcionamento, setTiposAcionamento] = useState([]);
	const [equipamentoId, setEquipamentoId] = useState(0);
	const [listaCondensadores, setListaCondensadores] = useState([]);

	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);

	//GET UNIDADE, REGIMES, MODELOS, MARCAS E EMPRESAS
	useEffect(() => {
		apiBombas.fetchApi(page).then((res) => {
			setListaBomba(res.data.items);
			setPagination(res.data);
		});
		apiMarca.fetchApi().then((res) => {
			setListaMarcas(res.data);
		});
		apiGrupo.fetchApi().then((res) => {
			setListaGrupos(res.data);
		});
		apiEnum.getAcionamentos().then(res => {
			setTiposAcionamento(res.data);
		});
	}, [page]);

	useEffect(() => {
		marcaId !== undefined &&
			apiModelo.fetchApi(marcaId, 'Bomba').then((res) => {
				setListaModelos(res.data);
			});
	}, [marcaId]);

	useEffect(() => {
		!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then((res) => {
				setListaEmpresas(res.data);
			});
	}, [grupoId]);

	useEffect(() => {
		!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then((res) => {
				setListaUnidades(res.data);
			});
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== undefined &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then((res) => {
				const regime = res.data.filter(
					(elemento) =>
						elemento.tipoRegime === 'Condensação'
				)[0];
				setRegime(regime);
			});
	}, [unidadeId]);

	useEffect(() => {
		regime !== undefined &&
			apiCondensadores.getByRegimeId(regime.id).then((res) => {
				setListaCondensadores(res.data);
			});
	}, [regime]);

	useEffect(() => {
		listaBomba.length > 0 &&
			listaBomba.forEach((elemento) => {
				apiCondensadores.getById(elemento.condensadorId).then((res) => {
					const condensador = res.data;
					setListaCondensadoresTabela(map => new Map(map.set(condensador.id, condensador)));
				});
			});
	}, [listaBomba]);

	useEffect(() => {
		listaCondensadoresTabela.size > 0 &&
			listaCondensadoresTabela.forEach((elemento) => {
				apiRegimes.getById(elemento.regimeId).then((res) => {
					const regime = res.data;
					setListaRegimeTabela(map => new Map(map.set(regime.id, regime)));
				});
			});
	}, [listaCondensadoresTabela]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
			listaRegimesTabela.forEach((elemento) => {
				apiUnidade.getById(elemento.unidadeId).then((res) => {
					const unidade = res.data;
					setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
				});
			});
	}, [listaRegimesTabela]);

	useEffect(() => {
		listaUnidadeTabela.size > 0 &&
			listaUnidadeTabela.forEach((elemento) => {
				apiEmpresa.getById(elemento.empresaId).then((res) => {
					const empresa = res.data;
					setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
				});
			});
	}, [listaUnidadeTabela]);

	function limparInputs() {
		setNome('');
		setPotenciaNominal(0);
		setMarcaId(0);
		setUnidadeId(0);
		setModeloId(0);
		setEmpresaId(0);
		setTipoAcionamento('');
		setRegime(0);
	}

	function deletar(id) {
		apiBombas
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Bomba deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar bomba!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const bomba = {
			nome: nome,
			potenciaNominal: parseFloat(potenciaNominal),
			acionamento: tipoAcionamento,
			tipoEquipamento: 'Condensador',
			equipamentoId: equipamentoId,
			modeloId: modeloId,
		};
		if (funcaoModal === 'Adicionar') {
			apiBombas
				.postApi(bomba)
				.then((res) => {
					limparInputs();
					sucesso('Bomba adicionada com sucesso!');
					atualizarLista();
					onClose();
				})
				.catch((error) => {
					erro('Erro ao adicionar bomba!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiBombas
				.putApi(idEditar, bomba)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Bomba editado com sucesso!');
					onClose();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar bomba!');
				});
		}
	}

	function getParentsObjects(id) {
		const condensador = getParentMap(listaCondensadoresTabela, id);
		const regime = getParentMap(listaRegimesTabela, condensador?.regimeId);
		const unidade = getParentMap(listaUnidadeTabela, regime?.unidadeId);
		const empresa = getParentMap(listaEmpresaTabela, unidade?.empresaId);
		return {
			condensador: condensador,
			regime: regime,
			unidade: unidade,
			empresa: empresa,
		};
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Condensador
								</th>

								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaBomba.map(
								(bomba, i) =>
									bomba.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													bomba.nome
												}
											</td>

											<td className="pl-[10px] text-[20px]">
												{
													getParentMap(
														listaCondensadoresTabela,
														bomba.condensadorId
													)
														?.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{
														getParentsObjects(
															bomba.condensadorId
														)
															?.unidade
															?.nome
													}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const {
																	condensador,
																	regime,
																	unidade,
																	empresa,
																} =
																	getParentsObjects(
																		bomba.condensadorId
																	);
																apiModelo
																	.getById(
																		bomba.modeloId
																	)
																	.then(
																		(
																			res
																		) => {
																			setMarcaId(
																				res
																					.data
																					.marcaId
																			);
																			setModeloId(
																				res
																					.data
																					.id
																			);
																		}
																	);
																setIdEditar(
																	bomba.id
																);
																setGrupoId(
																	empresa.grupoId
																);
																setNome(
																	bomba.nome
																);
																setPotenciaNominal(
																	bomba.potenciaNominal
																);

																setEmpresaId(
																	empresa.id
																);
																setRegime(
																	regime
																);
																setUnidadeId(
																	unidade.id
																);
																setTipoAcionamento(
																	bomba.acionamento.replaceAll("_", " ")
																);
																setPotenciaNominal(
																	bomba.potenciaNominal
																);
																setEquipamentoId(
																	bomba.condensadorId
																);

																setEquipamentoId(
																	condensador.id
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	bomba.nome
																);
																setIdRemover(
																	bomba.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{pagination !== undefined && (
						<PaginationBar pagination={pagination} />
					)}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Bomba
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome da bomba"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="potenciaNominal"
							>
								Potência Nominal (CV)
							</label>
							<input
								id="potenciaNominal"
								name="potenciaNominal"
								type="number"
								placeholder="Potencia Nominal"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={potenciaNominal}
								onChange={(e) =>
									setPotenciaNominal(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* ACIONAMENTO */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="tipoAcionamento"
							>
								Tipo Acionamento
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="tipoAcionamento"
								id="tipoAcionamento"
								required
								value={tipoAcionamento}
								onChange={(e) =>
									setTipoAcionamento(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{
									tiposAcionamento.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>
									))
								}
							</select>
						</div>
						{/* MARCA */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="marcaId"
							>
								Marca
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaMarcas.map((marca, i) => (
									<option
										value={marca.id}
										key={i}
									>
										{marca.nome}
									</option>
								))}
							</select>
						</div>
						{/* MODELO */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="modeloBombaId"
							>
								Modelo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="modeloBombaId"
								id="modeloBombaId"
								value={modeloId}
								required
								onChange={(e) =>
									setModeloId(
										parseInt(
											parseInt(
												e.target
													.value
											)
										)
									)
								}
							>
								<option label="   "></option>
								{listaModelos.map(
									(modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* GRUPOS */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoid"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="grupoid"
								id="grupoid"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* CONDENSADOR */}
						<div className="flex flex-col justify-start">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="equipamentoId"
							>
								Condensador
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="equipamentoId"
								id="equipamentoId"
								required
								value={equipamentoId}
								onChange={(e) =>
									setEquipamentoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaCondensadores
									.filter(
										(elemento) =>
											elemento.regimeId ===
											regime?.id
									)
									.map((condensador, i) => (
										<option
											value={
												condensador.id
											}
											key={i}
										>
											{
												condensador.nome
											}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<div onClick={onClose}>
								<AlertDialogCancel className="w-32">
									Cancelar
								</AlertDialogCancel>
							</div>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
